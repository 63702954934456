export default {
    count: 0,
    captchaIns: null,
    self: null,
    mobileIns: null,
    initNECaptcha(formData) {
        if (window.initNECaptcha) {
            if (typeof initNECaptcha == 'function') {
                if (this.count < 10) {
                    this.count++;
                    if (document.querySelector("#initNECaptcha")) {
                        this.count = 0;
                        initNECaptcha({
                                captchaId: "ace5749c1b5e494ba6a2a68edcd08853",
                                element: "#initNECaptcha",
                                mode: "bind",
                                width: "320px",
                                protocol: "https",
                                feedbackEnable: 0,
                                onVerify: (err, ret) => {
                                    if (err) return;
                                    formData.validate = ret.validate;
                                    A.updata.sendSms(formData).then(res => {
                                        if (res.code == 10000) {
                                            if(res.data.registerMark){
                                                formData.registerMark=true;
                                                formData.checked=true;
                                            }else{
                                                formData.registerMark=false;
                                            }
                                            this.mobileIns.start();
                                            return;
                                        } else this.self.$message(res.message);
                                        // this.self.$createToast({
                                        //     type: 'warn',
                                        //     txt: res.message,
                                        //     time: 2000
                                        // }).show();

                                    }, error => {
                                        console.log(error);
                                    });
                                    this.captchaIns.refresh();
                                }
                            },
                            instance => {
                                this.captchaIns = instance;
                                console.log("初始化成功后得到验证实例instance，可以调用实例的方法");
                            },
                            err => {
                                console.log(err);
                                alert("安全验证控件加载失败，请刷新页面重新");
                            })
                    } else {
                        setTimeout(() => {
                            this.initNECaptcha(formData);
                        }, 600);
                    }
                } else {
                    throw "element: #initNECaptcha 找不到";
                }
            } else {
                this.remoteLoad(formData);
            }
        } else {
            this.remoteLoad(formData);
        }
    },
    remoteLoad(formData) {
        var rtime = new Date().toISOString().substr(0, 13);
        A.remoteLoad("https://cstaticdun.126.net/load.min.js?t=" + rtime).then(res => {
            this.initNECaptcha(formData);
        }, err => {
            console.log(err)
        })
    }
}