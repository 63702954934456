/**
 * loading等待提示
 */


/**
 * 多loading任务自动处理
 * bol为Boolean参数
 * ture显示，false隐藏
 * 执行true与false的autoLoading数量是对等的
 */
var icount = 0;
const autoLoading = function (bol, apiname) {
    if (bol) {
        icount++;
        showLoading(apiname);
    } else if (--icount > 0) {
        hideLoading(apiname);
    } else {
        hideLoading(true);
        icount = 0;
    }
};
// loading
const showLoading = function (apiname) {
    if (apiname) {
        A.store.commit('global/setApiLoading', {
            loading: true,
            apiname
        })
    } else {
        A.store.commit('global/setApiLoading', true)
    }
};
const hideLoading = function (apiname) {
    if (typeof apiname == 'string') {
        A.store.commit('global/setApiLoading', {
            loading: false,
            apiname
        })
    } else {
        icount = 0;
        A.store.commit('global/setApiLoading', false)
    }
};

export default {
    autoLoading,
    showLoading,
    hideLoading
};