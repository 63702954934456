import event from './event'
import simple from './simple'
import lodash from 'lodash'
import loading from './loading'
import activeWin from './activeWin'
import reg from './reg'
import yidun from './yidun'
import catchUaction from './catchUaction'


// 允许修改的属性
const vars = ["firstPageName", "fromPageName", "currentPage", "pageContainer", "inpage"]
// 不允许修改的属性
const excludeKeys = ['assign', '__assign', 'on', 'once', 'off', 'emit']
const nav = {
    isBack: false
}
class A extends event {
    constructor() {
        super()
        // 初次运行的页面
        this.firstPageName = "";
        // 切换来源页面
        this.fromPageName = "";
        // 当前页面
        this.currentPage = null;
        // 所在外部容器环境
        this.pageContainer = 'web';
        // 是否已显示内页
        this.inpage = false

        this.on('enter-page', () => {
            this.inpage = true
        })
        if (window) {
            window.addEventListener('scroll', evt => {
                this.emit('win-scroll', {
                    y: evt.currentTarget.scrollY,
                    x: evt.currentTarget.scrollX
                }, evt)
                if ((evt.currentTarget.innerHeight + evt.currentTarget.scrollY + 1) > window.document.body.scrollHeight) {
                    this.emit('win-scrool-bottom', evt)
                }
            })
        }
    }
    assign() {
        for (let key in arguments) {
            this.__assign(arguments[key]);
        }
    }
    __assign(obj) {
        for (let key in obj) {
            if (key in this) {
                throw "无法扩展当前值:" + key;
            } else {
                Object.defineProperty(this, key, {
                    value: obj[key],
                    writable: false
                });
            }
        }
    }
    __assign2(obj) {
        for (let key in obj) {
            if (excludeKeys.find(k => k == key)) break;
            if (key in this) {
                throw "无法扩展当前值:" + key;
            } else {
                this["key:" + key] = obj[key]
            }
        }
    }
}
// 创建全局A实例
const a = new A();
// 代理A对象
// const poy = new Proxy(a, {
//     get: function (target, key) {
//         return target[key];
//     },
//     set: function (target, key, value) {
//         let ks = key.split(":");
//         if (ks.length == 2) {
//             target[ks[1]] = value;
//             return true;
//         } else if (vars.find(k => k == key)) {
//             target[key] = value;
//             return true;
//         } else {
//             throw "不允许直接修改或扩展A属性"
//         }
//     }
// })
// 默认配置扩展

a.assign({
    ...activeWin,
    ...loading,
    ...simple,
    reg,
    yidun,
    // 导航相关
    nav,
    catchUaction
});
if (window) {
    window.A = a;
    window.cuAtion = a.catchUaction;
    window._ = lodash;
}