/**
 * v1与v2版本号比较，v1是否比v2新
 * @param {string} v1 
 * @param {string} v2 
 */
export default function (v1, v2) {
    var v1Arr = v1.split(/\.|。|;|:|-|_|,/);
    var v2Arr = v2.split(/\.|。|;|:|-|_|,/);
    for (var i = 0; i < v1Arr.length; i++) {
        if (v2Arr[i]) {
            if (parseInt(v1Arr[i]) > parseInt(v2Arr[i])) {
                return true
            } else if (parseInt(v1Arr[i]) < parseInt(v2Arr[i])) {
                return false
            }
        } else {
            return true
        }
    }
    return false
}