export default function (point) {
    if (rx_module.name == 'resources' && rx_module) {
        if (point.y > 10) {
            if (A.router.currentRoute.name == 'company') {
                A.store.commit('global/setTopborder', "line")
                A.store.commit('global/setHideTopBar', true)
            } else if (['companyAll', 'companyIndustry', 'CompanyAccurate'].find(n => n == A.router.currentRoute.name)) {
                A.store.commit('global/setTopborder', "none")
                A.store.commit('global/setHideTopBar', true)
            } else {
                A.store.commit('global/setTopborder', "border")
                A.store.commit('global/setHideTopBar', true)
            }
            A.store.commit('res/setFixedMenu', true)
        } else {
            A.store.commit('global/setTopborder', false)
            A.store.commit('res/setFixedMenu', false)
            A.store.commit('global/setHideTopBar', false)
        }
    } else {
        if (point.y > 10) {
            A.store.commit('global/setTopborder', "border")
            A.store.commit('global/setHideTopBar', true)
        } else {
            A.store.commit('global/setTopborder', false)
            A.store.commit('global/setHideTopBar', false)
        }
    }
}