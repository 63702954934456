// import { Toast } from 'cube-ui';

export default {
    alert(value, callback, title = "", confirmButtonText = '确定') {
        getApp().$alert(value, title, {
            confirmButtonText,
            callback: callback || function () {}
        });
    },
    confirm(value, callback, title, confirmButtonText = '确定', cancelButtonText = '取消') {
        getApp().$confirm(value, title, {
            confirmButtonText,
            cancelButtonText
        }).then(() => {
            callback('confirm')
        }).catch(() => {
            callback('cancel')
        });
    },
    message(value) {
        getApp().$message(value);
    },
    Toast(value, time = 2000) {
        let ops = {
            txt: '',
            type: 'txt',
            time
        }
        if (typeof value == 'string') {
            ops.txt = value
        } else {
            Object.assign(ops, value)
        }
        // getApp().$message(value);
        // return Toast.$create(ops).show();
    }
}