const udeviceInfo = {
    device_platform: window && window.rxConfig && window.rxConfig.isWap ?'wap' : 'pc', //设备信息-客户端平台
    device_imei: '', //设备信息-设备ID
    device_system_version: '', //设备信息-设备系统版本
    device_brand: '', //设备信息-手机品牌
    device_model: '', //设备信息-手机型号
    net_ip: '', //网络信息-IP地址
    net_type: '', //网络信息-网络类型
    app_v: '', //app信息-当前版本
    app_mv: '', //app信息-热更新版本
    app_clientid: '', //app信息-app用户标识信息
    app_visit_bid: '', //app信息-用户访问唯一id
    app_launch_source: '', //app信息-启动来源
    app_down_source: '', //网络信息-下载渠道
    app_log_version: '1.0', //app信息-日志版本号
    app_RXTRACE: '', //app信息-渠道跟踪
    app_uuid: '', //app信息-用户唯一id，设备用户产生
    time_trigger: '', //时间信息-触发时间
    time_app_launch: '', //时间信息-最近app启动时间
    time_app_install: '', //时间信息-初次app启动时间
    user_id: '', //用户信息-用户ID
    user_type: '', //用户信息-用户类型
}
const pageData = {
    page_name: '', //当前页面-名称
    page_name_en: '', //当前页面-英文名称
    page_path: '', //当前页面-页面地址
    page_full_path: '', //当前页面-完整地址
    page_before_path: '', //当前页面-上级页面地址
    page_ugid: '', //当前页面-当前页面拜访唯一id
    page_enter_time: '' //当前页面进入时间
}
const catchData = {
    action: '', //埋点动作事件-行为
    target: '', //埋点动作事件-目标
    parameters: '', //埋点动作事件-参数
    value: '', //埋点动作事件-值
}

const logApiUrl = location.host.indexOf('rongxiangindustry.com')>-1 
                    ? "https://log.rongxiangipo.cn/log/pc"
                    : "http://192.168.10.161/log/pc";
// 用户访问id
const visitBid = function () {
    let vid = localStorage.getItem('visit_bid')
    if (!vid) {
        vid = A.guid()
        localStorage.setItem('visit_bid', vid)
    }
    return vid
}

const cua = {
    init() {
        console.log('catchUaction init');
        // 初始化用户信息
        if (A.db.udeviceInfo) {
            this.upInfo(A.db.udeviceInfo)
        }
        this.upInfo({ app_visit_bid: visitBid() })
        // 事件用户操作代理
        this.initEventListener();
    },
    
    initEventListener() {
        console.log('catchUaction initEventListener');
        document.body.addEventListener('click', evt => {
            let path = evt.path || evt.composedPath();
            for (var i in path) {
                if ('getAttribute' in path[i]) {
                    let ulog = path[i].getAttribute("data-ulog");
                    if (ulog != null) {
                        if (ulog == "") {
                            this.sendClick(path[i].innerText, "");
                        } else {
                            let pv = ulog.split('::');
                            let pms = pv[0];
                            let val = pv[1] || '';
                            this.sendClick(pms, val,'element','sendBeacon');
                        }
                        break;
                    }
                }
            }
        }, false)
        let ugid = "p-" + A.guid();
        let vTime =  (new Date()).getTime();
        Object.assign(pageData, {
            page_ugid: ugid, //当前页面-当前页面拜访唯一id
            page_enter_time: vTime
        })
        window.onload = ()=> {
            this.send('visit', 'page', 'enter_time', vTime, 'sendBeacon');
        }
        window.onbeforeunload = ()=> {
            this.send('visit', 'page', 'leave_time', (new Date()).getTime(),'sendBeacon');
            this.send('visit', 'page', 'duration', vPageTimeCount*1000, 'sendBeacon');
        }
        let vPageTimer = true;
        let vPageTimeCount = 1;
        function f_time_counter() {
            (vPageTimer === true) && (vPageTimeCount++);
            setTimeout(f_time_counter,1000);
        }
        var fc = function() {
            vPageTimer = document.hidden?false:true;
            if (document.hidden) {
                console.log('离开：'+vPageTimeCount);
            } else {
                console.log('回来：'+vPageTimeCount);
            }
        }
        if('onvisibilitychange' in document)this.f_event_add(document,"visibilitychange", fc);
        else if('onvisibilitychange' in window)this.f_event_add(window,"visibilitychange", fc);
        else if('onqbrowserVisibilityChange' in document)this.f_event_add(document,"qbrowserVisibilityChange", fc);
        f_time_counter();
    },
    //增加对象事件
    f_event_add(a, b, d) {
        a.attachEvent ? a.attachEvent("on" + b, function (b) { d.call(a, b) }) : a.addEventListener && a.addEventListener(b, d)
    },
    upInfo(_uinfo) {
        Object.assign(udeviceInfo, _uinfo);
        A.db.udeviceInfo = udeviceInfo;
    },
    // 更新当前页面
    changPage(to, from) {console.log("###")
        let fromPath = "";
        let ugid = "p-" + A.guid();
        let vTime = (new Date()).getTime();
        if (from.name) {
            fromPath = from.fullPath;
            // 离开页面时间
            this.pageSend('visit', 'page', 'leave_time', vTime)
            if (pageData.page_enter_time) {
                // 离开页面停留时长
                this.pageSend('visit', 'page', 'duration', vTime - pageData.page_enter_time)
            }
        }
        if (to.name) {
            // 配置当前页面
            Object.assign(pageData, {
                page_name: to.meta.title || "", //当前页面-名称
                page_name_en: to.name, //当前页面-英文名称
                page_path: to.path, //当前页面-页面地址
                page_full_path: to.fullPath, //当前页面-完整地址
                page_before_path: fromPath || document.referrer, //当前页面-上级页面地址
                page_ugid: ugid, //当前页面-当前页面拜访唯一id
                page_enter_time: vTime
            })
            // 进入页面时间
            this.pageSend('visit', 'page', 'enter_time', vTime)
        }
    },
    pageSend(action, target, parameters, value) {
        let baseData = Object.assign({}, udeviceInfo, pageData)
        let msg = Object.assign({}, baseData, { action, target, parameters, value });
        msg['time_trigger'] = (new Date()).getTime();
        A.updata.http.RS({
            method: 'POST',
            url: logApiUrl,
            data: msg
        })
    },
    // 发送用户点击事件埋点
    sendClick(parameters, value = '', target = 'element', sendType = '') {
        this.send('click', target, parameters, value, sendType)
    },
    // 发送用户事件埋点
    sendEvent(parameters, value = '', target = '') {
        this.send('event', target, parameters, value)
    },
    /**
     * 发送数据
     * @param {string} action 动作
     * @param {string} target 目标
     * @param {string} parameters 参数
     * @param {string} value 值
     */
    send(action, target, parameters, value, sendType = '') {
        let msg = Object.assign({
            page_name: document.title || "", //当前页面-名称
            page_name_en: A.router.currentRoute.name, //当前页面-英文名称
            page_path: location.origin, //当前页面-页面地址
            page_full_path: location.href, //当前页面-完整地址
            page_before_path: document.referrer, //当前页面-上级页面地址
        }, udeviceInfo, {
            action,
            target,
            parameters,
            value
        });
        // 触发时间
        msg['time_trigger'] = (new Date()).getTime();
        if (sendType == 'sendBeacon') {
            if (!navigator.sendBeacon) return;
            let sendUrl = logApiUrl;
            navigator.sendBeacon(sendUrl, JSON.stringify(msg));
        } else {
            A.updata.http.RS({
                method: 'POST',
                url: logApiUrl,
                data: msg
            })
        }
    },
    
}
export default cua