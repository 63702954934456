export default {
    get name() {
        var u = navigator.userAgent;
        if (/Android|Linux/i.test(u)) {
            return "android"
        } else if (/IOS|OS|iPhone/i.test(u)) {
            return "ios"
        } else {
            return "other"
        }
    },
    get iswx() {
        if (/micromessenger/i.test(navigator.userAgent)) {
            return true
        } else {
            return false
        }
    }
}