import moment from 'moment'
import 'moment/locale/zh-cn'
import os from './myos'
import vscompare from './vscompare'
import createProxy from './createProxy'
import winScrollEvent from './winscroll'
import _ from 'lodash'
import CryptoJS from 'crypto-js'
// promise缺省
const promiseReject = function (_obj) {
    return new Promise((resolve, reject) => {
        reject(_obj)
    })
}
// 剩余时间对象，天、小时、分钟、秒
const timeRem = function (_t) {
    return {
        d: parseInt(_t / 86400),
        h: parseInt(_t % 86400 / 3600),
        m: parseInt(_t % 86400 % 3600 / 60),
        s: parseInt(_t % 86400 % 3600 % 60)
    };
}
const getmintime = function () {
    let date = new Date();
    let oneDay = 1000 * 60 * 60 * 24;
    let ndtm =
        date.getHours() * 3600000 +
        date.getMinutes() * 60000 +
        date.getSeconds() * 1000;
    return date.getTime() - oneDay - ndtm;
};
// 时间格式返回
const timeFormat = function (value, fmt = "YYYY年MM月DD日 hh:mm") {
    if (value > getmintime()) {
        return moment(value).calendar();
    } else {
        return moment(value).format(fmt);
    }
}
// 日期特殊处理
const momentCalendar = function (val) {
    return moment(new Date(val)).calendar(null, {
        sameDay: now => {
            let last = moment(now).unix()-moment(new Date(val)).unix();
            if (last < 3600) {
                return Math.round(last / 60) + "分钟前"
            } else {
                return Math.round(last / 3600) + "小时前"
            }
        },
        lastDay: "[昨天]",
        lastWeek: "MM-DD",
        sameElse: now => {
            if (moment(now).year() == moment().year()) {
                return "MM-DD";
            } else {
                return "YYYY-MM-DD";
            }
        }
    });
}
//返回地址栏url的指定参数值
const getUrlParam = function (_name) {
    var reg = new RegExp("(^|)" + _name + "=([^&]*)(&|$)", "i");
    var r = window.location.href.match(reg);
    var context = "";
    if (r != null) {
        context = r[2];
    }
    reg = null;
    r = null;
    return context == null || context == "" || context == "undefined" ? "" : context;
}

// json转url参数
const jsonToParams = function (data) {
    return data ? Object.keys(data).map(function (key) {
        return encodeURIComponent(key) + "=" + encodeURIComponent(data[key]);
    }).join("&") : '';
}

// url参数(键值对字符串组)转json对象
const paramsToJson = function (value) {
    let param = {};
    value.replace(/([^?&]+)=([^?&]+)/g, function (s, v, k) {
        param[v] = k;
        return k + '=' + v;
    });
    return param;
}
// 普通文本转义至html标签
const textToHtml = function (val) {
    if (val) {
        return val.replace(/(\n|\r)/g, "<br>");
    } else {
        return "";
    }
}
// http协议判断
const getOrigin = function (host) {
    if (host.indexOf('http') === 0) return host;
    if (/^https:\/\//.test(window.location.origin)) {
        return "https://" + host;
    } else {
        return "http://" + host;
    }
}
// 加密
const aesEncrypt = function (data, key) {
    let str = CryptoJS.enc.Latin1.parse(key)
    let encrypted = CryptoJS.AES.encrypt(data, str, {
        iv: str,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.ZeroPadding
    });
    return encrypted.toString();
}
// 解密
const aesDecrypt = function (data, key) {
    let str = CryptoJS.enc.Latin1.parse(key)
    let decrypted = CryptoJS.AES.decrypt(data, str, {
        iv: str,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.ZeroPadding
    })
    decrypted = CryptoJS.enc.Utf8.stringify(decrypted);
    return decrypted;
}

// url编码
const encodeUrl = function (value) {
    if (_.isNil(value) || _.isError(value) || _.isFunction(value)) {
        console.warn('encodeUrl数据错误')
        return ""
    } else if (typeof value == 'string') {
        return encodeURIComponent(value)
    } else if (typeof value == 'number') {
        return value
    } else if (typeof value == 'object') {
        return encodeURIComponent(JSON.stringify(value))
    } else {
        return ""
    }
}
// url解码
const decodeUrl = function (value) {
    if (value) {
        try {
            var val = decodeURIComponent(value);
            var obj = JSON.parse(val);
            if (Object.keys(obj).length > 0) {
                return obj
            } else {
                return {}
            }
        } catch (error) {
            return decodeURIComponent(value);
        }
    } else {
        return ""
    }
}

// 新开窗口
const openWindow = function (url, target = "a") {
    if (!url) return;
    if (target) {
        if (target == 'a') {
            window.location.href = url;
        } else {
            window.open(url, target);
        }
    } else {
        A.router.push(url)
    }
}


// 大数据埋点
const bigJhj = function () {
    let img = document.querySelector("#tracejhjImg")
    if (img) img.parentNode.removeChild(img);
    let tracejhjImg = document.createElement('img')
    tracejhjImg.id = 'tracejhjImg'
    tracejhjImg.src = 'https://trace.trjcn.net/a/b.png?tracecode=TC_00&site=S003&' + Math.random()
    document.body.appendChild(tracejhjImg)
}

// GUID
const guid = function () {
    function S4() {
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    }
    return (S4() + S4() + "-" + S4() + "-" + S4() + "-" + S4() + "-" + S4() + S4() + S4());
}
// 判断用户是否登录状态
const ilogin = function (goLogin = true) {
    if (!A.db.token) {
        if (goLogin) {
            // 提示登录，并跳转
            A.message('请先登录');
            gotoLogin(true)
        }
        return false;
    } else {
        return true
    }
}
// 设置页面窗口标题
const setPageTitle = function (value) {
    document.title = value || '融象产盟';
}
// 打开登录页面
const gotoLogin = function (backPage = true) {
    if (backPage) {
        window.location.href = "/user/login?backpage=1"
    } else {
        window.location.href = "/user/login"
    }
}

// 打开登录页面接收参数
const gotoLoginParm = function (parm) {
    window.location.href = `/user/login?${encodeURIComponent(parm)}`
}

const remoteLoad = function (url, hasCallback) {
    return createScript(url)
    /**
     * 创建script
     * @param url
     * @returns {Promise}
     */
    function createScript(url) {
        var scriptElement = document.createElement('script')
        document.body.appendChild(scriptElement)
        var promise = new Promise((resolve, reject) => {
            scriptElement.addEventListener('load', e => {
                removeScript(scriptElement)
                if (!hasCallback) {
                    resolve(e)
                }
            }, false)
            scriptElement.addEventListener('error', e => {
                removeScript(scriptElement)
                reject(e)
            }, false)
            if (hasCallback) {
                window.____callback____ = function () {
                    resolve()
                    window.____callback____ = null
                }
            }
        })
        if (hasCallback) {
            url += '&callback=____callback____'
        }
        scriptElement.src = url
        return promise
    }

    /**
     * 移除script标签
     * @param scriptElement script dom
     */
    function removeScript(scriptElement) {
        document.body.removeChild(scriptElement)
    }
}
//验证码快捷登录
const codeQuickLogin = function (params) {
    return new Promise((resolve, reject) => {
        A.updata.quickLogin(params).then(res => {
            if (res.code == "10000") {
                if (res.data.token) {
                    let userToken = "Bearer " + res.data.token;
                    A.db.token = userToken;
                    resolve();
                }
            } else {
                if (window && window.rxConfig && window.rxConfig.isWap) {
                    A.Toast(res.message);
                } else {
                    A.message(res.message)
                }
                reject(res)
            }
        })
    });
}
//获取用户信息
const userInfo = function (data) {
    return new Promise((resolve, reject) => {
        A.updata.userLoginInfo().then(res => {
            if (res.code == '10000') {
                A.db.user = res.data.info;
                A.db.expertInfo = res.data.expertInfo;
                A.db.baseInfo = res.data;
                resolve(res.data.info);
            } else {
                reject(res);
            }
        }, reject)
    });
}
//登录并获取用户信息
const loginAndInfo = function (data) {
    return new Promise((resolve, reject) => {
        codeQuickLogin(data).then(res => {
            return userInfo();
        }, err => {
            reject(err);
        }).then(result => {
            resolve(result);
        });
    })
}
const isIE = function () {
    if (!!window.ActiveXObject || "ActiveXObject" in window)
        return true;
    else
        return false;
}
const winScrollListener = function () {
    A.on('win-scroll', winScrollEvent)
}
export default {
    os,
    moment,
    encodeUrl,
    decodeUrl,
    vscompare,
    createProxy,
    promiseReject,
    timeRem,
    timeFormat,
    momentCalendar,
    getUrlParam,
    jsonToParams,
    paramsToJson,
    textToHtml,
    getOrigin,
    openWindow,
    aesEncrypt,
    aesDecrypt,
    guid,
    ilogin,
    gotoLogin,
    gotoLoginParm,
    remoteLoad,
    setPageTitle,
    codeQuickLogin,
    userInfo,
    loginAndInfo,
    winScrollListener
}